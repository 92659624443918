import React, { useState } from "react";

import axios from "axios";

import { Link } from "gatsby";

import moment from "moment";

import { useForm } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import contactcallpic from "../../assets/images/connect/contact-call-banner-img.png";

import connnectblueicon from "../../assets/images/connect/connect-blude-icon.png";

import phonecircleicon from "../../assets/images/connect/phone-circle.png";

import emailsupporticon from "../../assets/images/connect/email-support-icon.png";

import missedcallicon from "../../assets/images/connect/missed-icon.png";


const Connectsupportpage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);

    const dataObj = {};
    const todayVal = moment(new Date()).format("MM-DD-YYYY h:mm:ss a");
    const practicingnew = data.practiseing;
    dataObj.submitteddate = todayVal;

    dataObj.first_name = data.firstname;
    dataObj.last_name = data.lastname;
    dataObj.emailid = data.email;
    dataObj.phoneno = data.phoneno;
    dataObj.gender = data.gender;
    dataObj.country = data.country;
    dataObj.subscribe = "Yes";
    dataObj.projectname = "SA";

    axios
      .post(
        "https://g6iqtcvhyl.execute-api.ap-south-1.amazonaws.com/api/sa/connectwithus",
        dataObj
      )
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          toast.success("Form submitted successfully");
          reset();
        } else {
          setLoading(false);
          toast.error("something went wrong");
        }
      });
  };

  return (
    <section className="connectsupportpage pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p className="theme-p-tag">
              Heartfulness is a silent current that has been flowing for ages
              and touches people all around us.{" "}
            </p>
          <div className="support-connect">
            <div className="col-lg-3 col-md-3 newcontact-box-height">
              <div className="">
                <img src={connnectblueicon} alt="Universal Prayer" />
                <h4 className="theme-h4 mt-3 mb-2 connect-heading">Contact</h4>
                <h6 className="connect-subhead gotham-light">SRCM RSA Lenasia Ashram</h6>
                <p className="theme-p-tag gotham-light theme-p-tag mg-bootom5">
                  {" "}
                  <Link className="" to=" ">18 Azure Road, Corner Multan  and Azure Road, Lenasia Ext 13,
                    Johannesburg, South Africa</Link>
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 newcontact-box-height mg-top20">
              <div className="">
                <img src={emailsupporticon} alt="Universal Prayer" />
                <h4 class="theme-h4 mt-3 mb-2 connect-heading">Email Support</h4>
                <p className="connect-subhead gotham-light theme-p-tag mg-bootom5">
                   SRCM RSA 
                </p>
                <p className="theme-p-tag gotham-light theme-p-tag mg-bootom5">
                <a className="" href="mailto:devanr@volunteer.heartfulness.org ">devanr@volunteer.heartfulness.org</a> <br/> 
              <a className="" href="mailto: rsa.secretary@heartfulness.org "> rsa.secretary@heartfulness.org </a> <br/> 
              <a className="" href="mailto: za.secretary@srcm.org ">   za.secretary@srcm.org</a>

                </p>

              </div>
            </div>
            </div>
          </div>

          <div className="col-md-6">
            <img src={contactcallpic} alt="Universal Prayer" />

            <div className="row mg-top40">

              {/* <div className="col-lg-6 col-md-6 newcontact-box-height">
                <div className="">
                  <img src={missedcallicon} alt="Universal Prayer" />
                  <h4 className="theme-h4 mt-3 mb-2">Missed Call Option</h4>
                  <span> (South Africa)</span>
                  <p className="gotham-light theme-p-tag">
                    If you would like to experience Heartfulness meditation with
                    a trainer close to you. Please give a missed call to{" "}
                    <b>+91-89395-89295</b> and we will get in touch with you.
                  </p>
                </div>
              </div> */}

              {/* <div className="col-lg-6 col-md-6 newcontact-box-height mg-top20">
                <div className="">
                  <img src={phonecircleicon} alt="Universal Prayer" />
                  <h4 className="theme-h4 mt-3 mb-2">Phone Support</h4>
                  <p className="gotham-light theme-p-tag mg-bootom5">
                    {" "}
                    S.Africa : +27 1234567890{" "}
                  </p>
                  <p className="gotham-light theme-p-tag mg-bootom5">
                    US/Canada: 1 844 879 4327
                  </p>
                  <p className="gotham-light theme-p-tag mg-bootom5">
                    WhatsApp: +27 1234567890
                  </p>
                </div>
              </div> */}
              <br />

            </div>
          </div>
        </div>
      </div>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          newestOnTop={false}
          hideProgressBar
          closeOnClick
          rtl={false}
        />
      </div>
    </section>
  );
};

export default Connectsupportpage;
