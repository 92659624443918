import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

// import Connectbreadcreumb from "../components/connect/Connectbreadcreumb";

import Connectbreadcreumb from "../components/connect/connect-breadcumb";

import Connectsentence from "../components/connect/connect-sentence";

import Connectsupportpage from "../components/connect/support-page";

// markup
const ConnectPage = () => {
  return (
    <div>
      <Seo title="Connect" />

      <Layout isSticky>
        <section>
          <Connectbreadcreumb />
        </section>

        <section>
          <Connectsupportpage />
        </section>

        <section>
          <Connectsentence />
        </section>
      </Layout>
    </div>
  );
};

export default ConnectPage;
