import React from "react";

import transmissionpic from "../../assets/images/connect/With-Transmission1.png";

export default function Connectsentence() {
  return (
    <section className="connectsentence-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3 className="heading-wrapper text-left"> Connect </h3>
            <p className="theme-p-tag">
              {" "}
              You will be contacted by a Heartfulness trainer through email and will be guided through three or four Heartfulness sessions on consecutive days. The Heartfulness sessions can be conducted remotely or in person, as is mutually convenient.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              There are no charges for the Heartfulness practice, the Heartfulness sessions, or any teaching offered through this website. You will be subscribed to the Heartfulness newsletter to help you proceed on your journey.{" "}
            </p>
          </div>

          <div className="col-md-6">
            <img src={transmissionpic} alt="Universal Prayer" />
          </div>
        </div>
      </div>
    </section>
  );
}
