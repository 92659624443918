import React from "react";

export default function Connectbreadcreumb() {
  return (
    <div className="breadcumb-sub-page about-banner subpage-padding">
    <div className="container">
      <div className="row">
        <div className="col-md-12 pad40">
          <h3 className="heading-wrapper about-breadcub-title text-center">
            {" "}
            Connect with us{" "}
          </h3>
          {/* <div classname="cent-item">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active> About Heartfulness</Breadcrumb.Item>
            </Breadcrumb>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  );
}
